import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'

import theme, { devices } from '../../theme'
import { ResponsiveLine } from '@nivo/line'
import Tooltip from './Tooltip'
import DataRange from './DataRange'
import GhostState from './GhostState'
import Composition from './Composition'
import {
  setDataRange,
  getAverage,
  transformData,
  setPriceDecimal
} from '../../utils'

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 0;
  border: 1px solid ${ props => props.theme.color.grey[2] };
  border-radius: ${ props => props.theme.radius.box };
  box-shadow: 0 4px 20px ${ rgba('#000', 0.12) };

  text {
    alignment-baseline: baseline !important; /* reset since Chrome wrongfully sees it as valid */
    dominant-baseline: middle;
  }

  line {
    opacity: 0 !important;
  }

  @media (${ devices.desktop }) {
    width: 100%;
    margin: 0;
    font-size: 1.6rem;
  }
`

const ChartHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2em;
`

const Price = styled.div`
  display: flex;
  align-items: center;
`

const Avg = styled.div`
  color: ${ props => (props.sign ? 'red' : '#28bb66;') };

  strong {
    background-color: ${ props =>
    props.sign ? rgba('red', 0.09) : rgba('#28bb66', 0.09) };
    font-size: 2rem;
    margin-left: 0.5em;
    border-radius: 50px;
    padding: 0.2em 0.5em;

    &::before {
      content: ${ props => (props.sign ? '\'-$\'' : '\'+$\'') };
    }
  }

  span {
    margin-left: 0.3em;
  }
`

const Value = styled.span`
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1;
`

const ChartInner = styled.div`
  height: 320px;

  & > div {
    height: 390px !important;
  }
`

const Chart = ({ data, range, comp }) => {
  const withDataDate = transformData(data)

  let newData
  useMemo(() => {
    newData = setDataRange(withDataDate, range)
  }, [data, range])

  const [chartData, setChartData] = useState([])
  const [price, setPrice] = useState({})
  const [loading, setLoading] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    setChartData(() => [
      {
        id: 'index',
        color: theme.color.primaryColor,
        data: newData
      }
    ])
  }, [data, range])

  useEffect(() => {
    if (chartData.length > 0) {
      setLoading(() => false)
      const getPrices = getAverage(chartData[0].data)
      setPrice(() => {
        return {
          lastPrice: getPrices.lastPrice,
          difference: Math.abs(getPrices.difference().price),
          diffInPercentage: getPrices.differenceInPercent(),
          isDifNeg: getPrices.difference().isNegative()
        }
      })
    }
  }, [chartData])

  useEffect(() => setLoading(() => true), [range])

  useEffect(() => setScreenWidth(window.innerWidth))

  if (loading || chartData.length > 0) {
    return (
      <>
        <ChartContainer>
          <ChartHeader>
            <Price>
              {price.lastPrice && (
                <>
                  <Value>${setPriceDecimal(price.lastPrice)}</Value>
                  <Avg sign={price.isDifNeg}>
                    <strong>{setPriceDecimal(price.difference)}</strong>
                    <span>({price.diffInPercentage})</span>
                  </Avg>
                </>
              )}
            </Price>
            <DataRange active={range} />
          </ChartHeader>
          <ChartInner>
            <ResponsiveLine
              animate={false}
              areaOpacity={0.06}
              axisLeft={null}
              axisBottom={{
                format: range === '1M' ? '%d %b' : '%b %Y',
                orient: 'bottom',
                tickSize: 0,
                tickPadding: 21,
                tickValues: screenWidth < 780 ? 3 : 5
              }}
              colorBy={d => d.color}
              crosshairType={`bottom`}
              curve={'natural'}
              data={chartData}
              isInteractive={true}
              lineWidth={2}
              margin={{
                top: 10,
                right: 0,
                bottom: 110,
                left: 0
              }}
              xScale={{
                type: 'time',
                format: 'native',
                precision: 'month',
                min: 'auto',
                max: 'auto'
              }}
              yScale={{
                type: 'linear',
                min: 0,
                max: 'auto'
              }}
              enableGridX={false}
              enableGridY={false}
              enableDots={false}
              enableArea={true}
              enablePoints={false}
              tooltip={d => <Tooltip content={d} />}
              colors={theme.color.primaryColor}
              theme={{
                axis: {
                  domain: {
                    line: {
                      stroke: theme.color.primary[1],
                      strokeWidth: 0.2,
                      strokeDasharray: 0
                    }
                  }
                }
              }}
              useMesh={true}
            />
          </ChartInner>
        </ChartContainer>
        {chartData.length > 0 && comp && <Composition elements={comp} />}
      </>
    )
  } else return <GhostState />
}

Chart.propTypes = {
  data: PropTypes.array.isRequired
}

export { Chart as default, ChartContainer }
