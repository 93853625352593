import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { rgba } from 'polished'

import { StoreCtx } from '../store'
import SEO from '../components/seo'
import {
  Wrapper,
  Container,
  Row,
  Column,
  Inner
} from '../components/ui/layout/Grid'
import PageIntro from '../components/Hero/PageIntro'
import { ContentBox } from '../components/ui/layout/ContentBox'
// import Chart from '../components/Chart/nivo'
import Chart from '../components/Chart/'
import theme, { devices } from '../theme'
import { SectionHeading } from '../components/ui/Heading'
import Newsletter from '../components/Newsletter'

// Illustrations
import indexHero from '../images/assets/index_hero.svg'
import dataIllustration from '../images/assets/data.svg'
import assetIllustration from '../images/assets/asset_cat.svg'
import methodIllustration from '../images/assets/construction.svg'
import dataSourceIllustration from '../images/assets/data_sourcer.svg'

// Styles icons
import momentum from '../images/assets/icons/momentum_icon.svg'
import size from '../images/assets/icons/size_icon.svg'
import value from '../images/assets/icons/value_icon.svg'
import reversal from '../images/assets/icons/reversal_icon.svg'
import volatility from '../images/assets/icons/volatility_icon.svg'

// Misc icons
import check from '../images/assets/check_icon.svg'

const PerfContent = styled.div`
  color: ${ rgba('#fff', 0.6) };
  padding: 1.5em 2em;
`

const PerfRow = styled(Row)`
  h3 {
    position: relative;
    color: #fff;
    padding: 0.5em 0;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 3px;
      background-color: ${ props => props.accentColor };
    }
  }

  ${ PerfContent } {
    border: 1px solid ${ props => props.accentColor };
    border-left: 5px solid ${ props => props.accentColor };
  }
`

const StylesContentBox = styled(ContentBox)`
  border-color: #f8f3fc;
  box-shadow: none;
  height: 100%;

  p {
    font-size: 1.8rem;
  }

  img {
    max-width: 64px;
  }
`

const ReviewContentBox = styled(ContentBox)`
  margin-bottom: 2em;
  padding-left: 2em;

  h3 {
    margin-top: 0;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    background-color: ${ props => rgba(props.theme.color.primaryColor, 0.08) };
    width: 60px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
  }

  &::after {
    background-image: url(${ check });
    background-position: center center;
    background-size: 12px;
    background-repeat: no-repeat;
    background-color: ${ props => props.theme.color.primaryColor };
    width: 42px;
    height: 42px;
    left: calc(100% - 60px - (42px / 2));
    top: 50%;
    transform: translateY(-50%);
    border-radius: 200px;
    box-shadow: 0 0 0 8px #fff;
  }
`

const Legal = styled.div`
  border: 1px solid ${ props => props.theme.color.grey[1] };
  padding: 2em;

  h5 {
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
  }
`

const Assets = ({ data }) => {
  const { edges: chartData } = data.allIndexData

  return (
    <>
      <SEO title="Investabit Cryptocurrency Index" />
      <PageIntro
        title="Investabit Cryptocurrency Index - ICI 15"
        topTitle="Introducing"
        subtitle="Takes careful consideration of each asset to reflect true value in the cryptocurrency economy."
        cta={{ to: '/#subscribe', label: 'Subscribe For Updates', appearance: 'primary' }}
        image={{
          src: indexHero,
          alt: 'Investabit Index Index',
          css: `
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          z-index: -1;
          @media(${ devices.desktopWide }) {
            top: 70px;
          }
          @media(${ devices.tabletWide }) {
            top: 120px;
          }
          @media(${ devices.tablet }) {
            top: 200px;
          }
          @media(${ devices.mobile }) {
            display: none;
          }
          `
        }}
      />
      <Container>
        <StoreCtx.Consumer>
          {({ data }) => {
            return chartData.map(chart => (
              <Chart
                key={chart.node.id}
                data={chart.node.performance}
                range={data.range}
                comp={chart.node.composition}
              />
            ))
          }}
        </StoreCtx.Consumer>
      </Container>
      <Container size="md">
        <Row dir="column">
          <Column align="center">
            <SectionHeading>
              <h2>The Investabit Index(es)</h2>
              <p>
                Through years of data aggregation and predictive analytics,
                Investabit has been broadly grouping assets and tracking the
                overall performance of these groups.
              </p>
            </SectionHeading>
          </Column>
        </Row>
      </Container>
      <Container>
        <Row>
          <Column>
            <Inner>
              <img
                src={dataIllustration}
                alt="Data stream illustration"
              />
            </Inner>
          </Column>
        </Row>
        <Row>
          <Column>
            <ContentBox
              bg="#8dd1ff"
              bgPattern={true}
            >
              <h2>Asset Balancing</h2>
              <p>
                Each asset is weighted according to its total market
                capitalization across all exchanges, which accounts not only for
                changes in price, but also supply.
              </p>
            </ContentBox>
          </Column>
          <Column>
            <Inner>
              <img
                src={assetIllustration}
                alt="Data stream illustration"
              />
            </Inner>
          </Column>
        </Row>
      </Container>
      <Wrapper bgColor={theme.color.grey[6]}>
        <Container size="md">
          <Row dir="column">
            <Column align="center">
              <SectionHeading inverted={true}>
                <h2>Performance</h2>
                <p>
                  Below are a few factors commonly used to measure a portfolio's
                  ability to gain alpha within the marketplace.
                </p>
              </SectionHeading>
            </Column>
          </Row>
        </Container>
        <Container>
          <PerfRow
            mt="4em"
            mb="4em"
            accentColor={theme.color.primaryColor}
          >
            <Column>
              <h3>Sharpe Ratio</h3>
            </Column>
            <Column>
              <PerfContent>
                A measure that indicates the average return minus the risk-free
                return divided by the standard deviation of return on an
                investment.
              </PerfContent>
            </Column>
          </PerfRow>
          <PerfRow
            mt="4em"
            mb="4em"
            accentColor={theme.color.accentColor}
          >
            <Column>
              <h3>Annualized Volatility</h3>
            </Column>
            <Column>
              <PerfContent>
                Annualized volatility describes the variation in an instrument's
                value over the course of a year. This measure indicates the
                level of risk associated with an investment.
              </PerfContent>
            </Column>
          </PerfRow>
          <PerfRow
            mt="4em"
            mb="4em"
            accentColor={'#FFC456'}
          >
            <Column>
              <h3>Monthly Volatility</h3>
            </Column>
            <Column>
              <PerfContent>
                Monthly volatility describes the variation in an instrument's
                value over the course of a month. This measure indicates the
                level of risk associated with an investment.
              </PerfContent>
            </Column>
          </PerfRow>
        </Container>
      </Wrapper>
      <Container size="md">
        <Row
          dir="column"
          mt="5em"
        >
          <Column align="center">
            <SectionHeading>
              <h2>Construction Methodology</h2>
              <p>
                Assignment for the category is determined by it’s primary
                use-case and then the specific function of the asset. For
                example if a token is used primarily by banks we will associate
                it with banking or currency.
              </p>
            </SectionHeading>
          </Column>
        </Row>
      </Container>
      <Container size="md">
        <Row>
          <Column>
            <Inner>
              <img
                src={methodIllustration}
                alt="Construction methodologies illustration"
              />
            </Inner>
          </Column>
        </Row>
      </Container>
      <Wrapper bgColor="#FCF8FF">
        <Container>
          <Row
            dir="column"
            mt="5em"
          >
            <Column align="center">
              <SectionHeading>
                <h2>5 Style Factors</h2>
              </SectionHeading>
            </Column>
          </Row>
          <Row
            grid={1 / 2}
            css={`
              background-color: #fff;
            `}
          >
            <Column>
              <StylesContentBox>
                <img
                  src={momentum}
                  alt="Actual Momentum"
                />
                <h3>Actual Momentum</h3>
                <p>
                  Momentum is the rate of acceleration for an instrument's price
                  or volume. In technical analysis, momentum is considered an
                  oscillator and refers to the force or speed of movement; it is
                  usually defined as a rate. In the world of investments,
                  momentum refers to the rate of change of price movements for a
                  particular instrument—that is, the speed at which the price is
                  changing.
                </p>
              </StylesContentBox>
            </Column>
            <Column>
              <StylesContentBox>
                <img
                  src={size}
                  alt="Size"
                />
                <h3>Size</h3>
                <p>
                  Refers to the magnitude of an offering, an order, or a trade.
                  Size is relative from market to market and security to
                  security.
                </p>
              </StylesContentBox>
            </Column>
            <Column>
              <StylesContentBox>
                <img
                  src={value}
                  alt="Value"
                />
                <h3>Value</h3>
                <p>
                  A subjective estimate of what a willing buyer would pay a
                  willing seller for a given asset, assuming both have a
                  reasonable knowledge of the asset's worth.
                </p>
              </StylesContentBox>
            </Column>
            <Column>
              <StylesContentBox>
                <img
                  src={reversal}
                  alt="Short Term Reversal"
                />
                <h3>Short Term Reversal</h3>
                <p>
                  The short-term reversal anomaly is a phenomenon whereby
                  instruments with relatively low returns over the past month or
                  week earn positive abnormal returns in the following month or
                  week, and instruments with high returns earn negative abnormal
                  returns.
                </p>
              </StylesContentBox>
            </Column>
            <Column>
              <StylesContentBox>
                <img
                  src={volatility}
                  alt="Volatility"
                />
                <h3>Volatility</h3>
                <p>
                  Volatility is a statistical measure of the dispersion of
                  returns for a given security or market index. Volatility can
                  either be measured by using the standard deviation or variance
                  between returns from that same security or market index.
                  Commonly, the higher the volatility, the riskier the security.
                </p>
              </StylesContentBox>
            </Column>
            <Column>
              <StylesContentBox />
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Container>
        <Row
          align="center"
          mt="10em"
          mb="5em"
        >
          <Column
            css={`
              margin-right: 5em;
            `}
          >
            <ContentBox
              bg={theme.color.primaryColor}
              bgPattern={true}
            >
              <h2>Data Sources</h2>
              <p>
                Investabit collects data across 100 sources and aggregates it
                internally.
              </p>
            </ContentBox>
          </Column>
          <Column>
            <img
              src={dataSourceIllustration}
              alt="Data Source Illustration"
            />
          </Column>
        </Row>
      </Container>
      <Wrapper bgColor={theme.color.grey[0]}>
        <Container size="md">
          <Row
            dir="column"
            mt="5em"
          >
            <Column align="center">
              <SectionHeading>
                <h2>Review and Oversight</h2>
                <p>
                  Investabit’s team meets once per quarter with the following
                  intentions.
                </p>
              </SectionHeading>
            </Column>
          </Row>
          <Row grid={1 / 1}>
            <Column>
              <ReviewContentBox>
                <h3>Data Integrity</h3>
                <p>Reviewing the accuracy and availability of data sources.</p>
              </ReviewContentBox>
            </Column>
            <Column>
              <ReviewContentBox>
                <h3>Calculations</h3>
                <p>Reviewing calculations of the index level for anomalies.</p>
              </ReviewContentBox>
            </Column>
            <Column>
              <ReviewContentBox>
                <h3>Construction Methodology</h3>
                <p>
                  Determining whether any changes to the index construction and
                  methodology are required.
                </p>
              </ReviewContentBox>
            </Column>
            <Column>
              <ReviewContentBox>
                <h3>Performance</h3>
                <p>
                  Discussing performance and applying new methodologies (Machine
                  Learning, Reinforcement Learning) to the index.
                </p>
              </ReviewContentBox>
            </Column>
          </Row>
        </Container>
      </Wrapper>
      <Container>
        <Newsletter />
      </Container>
      <Container>
        <Row>
          <Column>
            <Legal>
              <h5>Legal Disclaimer</h5>
              <p>
                Investabit’s Indexes and this website are provided on an "as is"
                basis and Investabit Capital Inc makes no representations or
                warranties as to the quality, accuracy or suitability of
                Investabit Index or this website. You acknowledge and agree that
                Investabit Captial, Inc. is not acting as an investment advisor
                or portfolio management by providing access to the Investabit
                Indexes and shall bear no responsibility or liability for any
                loss or damage, howsoever caused, including due to amendment of
                this website or the index methodology or calculations, resulting
                from or in connection with your use of Investabit Indexes or
                this website for any purpose.
              </p>
            </Legal>
          </Column>
        </Row>
      </Container>
    </>
  )
}

export const ChartQuery = graphql`
  query chartData {
    allIndexData {
      edges {
        node {
          id
          composition {
            binance_coin
            bitcoin
            bitcoin_cash
            cardano
            chainlink
            dash
            eos
            ethereum
            litecoin
            monero
            neo
            ripple
            stellar
            tether
            tron
          }
          performance {
            x
            y
            final
          }
        }
      }
    }
  }
`

export default Assets
