import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const HeadingStyled = styled.div`
  text-align: center;
  color: ${ props => (props.inverted ? '#fff' : 'inherit') };

  h1,
  h2 {
    color: ${ props => (props.inverted ? '#fff' : 'inherit') };
    margin-bottom: 0;
    margin-top: 0;
  }

  p {
    margin: 0;
    padding: 0.5em 0;
    opacity: 0.5;
  }
`

const SectionHeading = ({ inverted, children }) => (
  <HeadingStyled inverted={inverted}>{children}</HeadingStyled>
)

SectionHeading.propTypes = {
  inverted: PropTypes.bool,
  children: PropTypes.node.isRequired
}
export { SectionHeading }
