import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rgba } from 'polished'
import startCase from 'lodash/startCase'

const ElementColors = [
  '#6D3580',
  '#834896',
  '#9A6EA8',
  '#BA9BC4',
  '#CC4165',
  '#E15C7E',
  '#E4889F',
  '#F3A9BB',
  '#E4734F',
  '#EA9479',
  '#F7A78E',
  '#FECDBE',
  '#FFE26F',
  '#FFEDA4',
  '#FFF6D5'
]
const CompStyle = styled.div`
  margin: 2em 0;
  width: 100%;
`

const ElementCont = styled.div`
  width: 100%;
  margin: 0 auto;
`

const Element = styled.div`
  display: inline-block;
  background-color: ${ props => props.bgColor };
  width: ${ props => props.width };
  height: 12px;
  position: relative;
  transition: box-shadow, background-color 0.25s ease;
  cursor: pointer;

  &:first-child {
    border-radius: 20px 0 0 20px;
  }

  &:last-child {
    border-radius: 0 20px 20px 0;
  }

  :hover {
    background-color: ${ props => props.theme.color.accentColor };
    box-shadow: 0 2px 6px ${ props => rgba(props.theme.color.accentColor, 0.3) };
  }
`

const Tooltip = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 0.25em 1em;
  position: absolute;
  bottom: calc(100% + 0.5em);
  text-align: center;
  word-wrap: nowrap;
  left: 50%;
  transform: translateX(-50%);
  display: ${ props => (props.active ? 'block' : 'none') };
  border: 1px solid ${ props => props.theme.color.grey[2] };
  box-shadow: 0 4px 20px ${ props => props.theme.color.grey[1] };
  white-space: nowrap;

  h5 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    display: inline;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: ${ props => props.theme.color.grey[3] } transparent transparent transparent;
  }
`

const Composition = ({ elements }) => {
  const sortElements = Object.entries(elements).sort((a, b) => b[1] - a[1])
  const [tooltip, setTooltip] = useState()

  return (
    <CompStyle>
      <ElementCont>
        {sortElements.map((element, idx) => (
          <React.Fragment key={idx}>
            <Element
              bgColor={ElementColors[idx]}
              width={`${ element[1] }%`}
              onMouseOver={() => setTooltip(element[0])}
              onMouseOut={() => setTooltip(false)}
            >
              <Tooltip active={tooltip === element[0]}>
                <span>
                  <h5>{startCase(element[0])}: </h5>
                  <strong>{`${ element[1].toFixed(2) }%`}</strong>
                </span>
              </Tooltip>
            </Element>
          </React.Fragment>
        ))}
      </ElementCont>
    </CompStyle>
  )
}

Composition.propTypes = {
  elements: PropTypes.object.isRequired
}

Element.propTypes = {
  width: PropTypes.string.isRequired,
  bgColor: PropTypes.oneOf(ElementColors).isRequired
}

Tooltip.propTypes = {
  active: PropTypes.bool.isRequired
}

export { Composition as default, Tooltip }
