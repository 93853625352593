import React from 'react'
import styled, { keyframes } from 'styled-components'
import { ChartContainer } from './'

const Wave = keyframes`
  0% {
    background-position:0% 50%;
    opacity: 0.8;
  }

  50% {
    background-position:100% 50%;
    opacity: 1;
  }

  100% {
    background-position:0% 50%;
    opacity: 0.8;
  }

`

const Block = styled.div`
  background: linear-gradient(
    90deg,
    ${ props => props.theme.color.grey[1] },
    ${ props => props.theme.color.grey[2] }
  );
  background-size: 200% 200%;
  width: ${ props => props.width };
  height: ${ props => props.height };
  border-radius: 4px;
  animation: ${ Wave } 1s ease infinite;
`

const GhostState = () => (
  <ChartContainer style={{ padding: '1.2em' }}>
    <Block
      width="220px"
      height="35px"
    />
    <Block
      width="100%"
      height="320px"
      css={`
        margin-top: 1.2em;
      `}
    />
  </ChartContainer>
)

export default GhostState
