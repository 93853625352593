import React from 'react'
import dayjs from 'dayjs'

import { Tooltip as TooltipStyle } from './Composition'
import { setPriceDecimal } from '../../utils'

const Tooltip = ({ content }) => {
  const { point } = content
  return (
    <TooltipStyle
      active={true}
      css={`
        border-radius: 8px;
      `}
    >
      <div>
        <strong>${setPriceDecimal(point.data.y)}</strong>
        <div>{dayjs(point.data.x).format('MMMM DD')}</div>
      </div>
    </TooltipStyle>
  )
}

export default Tooltip
