import React from 'react'
import PropTypes from 'prop-types'
import { StoreCtx } from '../../store'
import styled from 'styled-components'

import { LABELS } from '../../utils/constants'
import { devices } from '../../theme'

const Range = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (${ devices.mobile }) {
    position: absolute;
    top: -30px;
    left: 0;
  }
`

const RangeButton = styled.button`
  background-color: #fff;
  text-transform: uppercase;
  padding: 0 0.5em;
  font-size: 1.4rem;
  color: ${ props =>
    props.active ? props.theme.color.primaryColor : props.theme.color.grey[3] };
  border: 0;
  outline: 0;
  cursor: pointer;

  :hover {
    color: ${ props => props.theme.color.grey[5] };
  }
`

const DataRange = ({ active }) => {
  return (
    <Range>
      <StoreCtx.Consumer>
        {({ actions }) => {
          return Object.keys(LABELS).map((label, idx) => {
            return (
              <RangeButton
                onClick={() => actions.setRange(label)}
                key={idx}
                active={label === active}
              >
                {label}
              </RangeButton>
            )
          })
        }}
      </StoreCtx.Consumer>
    </Range>
  )
}

DataRange.propTypes = {
  active: PropTypes.oneOf(Object.keys(LABELS))
}

export default DataRange
